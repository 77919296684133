.form-search-line {
  width: 90%;
  border: 0;
  border-bottom: 1px solid rgba(128, 128, 128, 0.4);
  outline: none;
  transition: all .4s;

  &:focus {
    border-bottom: 1px solid black;
  }
}

.table-text-limit {
  max-height: 100px;
  width: 100%;
  overflow-y: hidden;
  position: relative;
  p{
    line-height: 19px !important;
    margin-bottom: 0;
  }
  &:after{
    content: "";
    z-index: 10;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: rgb(255,255,255);
    background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 2%, rgba(255,255,255,0.22172619047619047) 21%);
  }
}

.table-sort-custom {
  ul {
    margin: 0;
    padding: 0;
    width: 100%;
    white-space: nowrap;

    li {
      text-align: -webkit-match-parent;
    }
  }
}
