@import "variables";

.text-primary {
  color: $primary !important;
}

.btn{
  border: none;
  border-radius: 30px;
  box-shadow: 1px 1px 1px gray !important;
}

.btn-primary {
  background: $primary;
}

.btn-primary:hover {
  background: $primary;
}

.btn.btn-icon {
  width: 45px;
  height: 45px;
  padding: 0;
  border-radius: 50%;
  font-size: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
