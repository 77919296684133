.App {
  text-align: center;
}

.content {
  height: 100%;
  scroll-behavior: smooth;
}

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

.h-wrap {
  height: calc(100vh - 62px);
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}

.table-field {
  thead  {
    background: #bfbfbf !important;
  }

  th, td {
    padding-top: 25px;
    padding-bottom: 2px;
    border: 1px solid;
  }

}
