/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
.sidebar {
  min-width: 250px;
  max-width: 250px;
  background: #fff;
  color: #383eb3;
  box-shadow: 2px -1px 2px rgba(72,148,255,.35);
  margin-left: -250px;
  transition: all 0.5s;
}

.sidebar.is-open {
  margin-left: 0;
  transition: .5s;
}


.sidebar-header {
  background: #fff;
}

.sidebar-header h5{
  color: #383eb3;
  padding: 1em;
}

.sidebar ul p {
  color: #fff;
  padding: 10px;
}

.menu-open {
  background: #fff;
}

.nav-item:hover {
  color: #7386D5;
  background: #fff;
}

.items-menu {
  color: #fff;
  background: #6d7fcc;
}

li a.dropdown-toggle::after {
  display: inline-flex;
  position: relative;
  left: 60%;
  top: 10%
}

.sidebar-header > span {
  position: relative;
  float: right;
  margin: .5em;
  font-size: 2rem;
  cursor: pointer;
  display: none;
}
.side-menu {
  height: calc(100vh - 139px);
  overflow-y: scroll;
}

.side-menu::-webkit-scrollbar {
  width: 10px;
}

.side-menu::-webkit-scrollbar-thumb {
  background: #383eb3;
  border-radius: 3px;
}

.side-menu::-webkit-scrollbar-thumb:hover {
  background: #3A4C99;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
.content {
  padding: 20px;
  margin-left: 0;
  height: calc(100vh - 58px);
}

@media only screen and (max-width: 500px) {
  body {
    overflow:  hidden;
  }

  .content.is-open {
    margin-left: 100%;
  }

  .sidebar.is-open {
    min-width: 100%;
    max-width: 100%;
    margin-left: 0;
    transition: all 0.5s, height 0s;
  }

  .sidebar.is-open > .sidebar-header span{
    display: unset;
  }

  li a.dropdown-toggle::after {
    display: inline-block;
    position: relative;
    left: 68%;
  }
}
