@import '~bootstrap/scss/variables';

$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$grays: map-merge(
    (
      "100": $gray-100,
      "200": $gray-200,
      "300": $gray-300,
      "400": $gray-400,
      "500": $gray-500,
      "600": $gray-600,
      "700": $gray-700,
      "800": $gray-800,
      "900": $gray-900
    ),
    $grays
);

$blue: #383EB3; // change
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #ff5252; // change
$orange: #fd7e14;
$yellow: #ffba57; // change
$green: #9ccc65; // change
$teal: #20c997;
$cyan: #C0D7FC; // change

$colors: map-merge(
    (
      "blue": $blue,
      "indigo": $indigo,
      "purple": $purple,
      "pink": $pink,
      "red": $red,
      "orange": $orange,
      "yellow": $yellow,
      "green": $green,
      "teal": $teal,
      "cyan": $cyan,
      "white": $white,
      "gray": $gray-600,
      "gray-dark": $gray-800
    ),
    $colors
);

$primary: $blue; // change
$secondary: $gray-600; // change
$success: $green; // change
$info: $cyan; // change
$warning: $yellow; // change
$danger: $red; // change
$light: $gray-100; // change
$dark: $gray-800; // change
$primary-light: #7755FE;;

$theme-colors: map-merge(
    (
      "primary": $primary,
      "secondary": $secondary,
      "success": $success,
      "info": $info,
      "warning": $warning,
      "danger": $danger,
      "light": $light,
      "dark": $dark
    ),
    $theme-colors
);

