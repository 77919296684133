@import "../Styles/variables";

.clickable {
  cursor: pointer;
}

.btn-float {
  padding: 0;
  position: absolute;
  top: -1px;
  left: -12px;
}

.btn-link {
  box-shadow: none !important;
}

.ant-popover {
  z-index: 2000;
}

.h-90 {
  height: 90%;
}

.h-10 {
  height: 10%;
}


.custom-scroll {
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 5px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #c4c4c4;
    border-radius: 8px;
  }

  &-light:hover::-webkit-scrollbar-thumb:hover {
    background: gray;
  }

  &-primary::-webkit-scrollbar-thumb:hover {
    background: $primary;
  }
}

.active-section {
  transition: all .5s;
  border-left: 6px solid $primary !important;
  color: $primary;
  font-weight: bold;
}


.react-time-picker__wrapper {
  border: none !important;
}

.font-roboto {
  font-family: "Roboto" !important;
}


.hover-mode {
  * {
    cursor: pointer;
  }

  label {
    margin: 0;
    padding-left: 2px;
    padding-top: 8px;
    padding-bottom: 8px;
  }

  &:hover {
    background: #ecf0f5;
  }
}
